<template>
  <win-block>
    <template #win-title-left>Информация</template>
    <v-form>
      <v-text-field
          label="API Ключ"
          :value="key"
          :append-icon="key_load ? 'mdi-cached z-spin':'mdi-cached'"
          :loading="key_load"
          readonly
          filled
          @click:append="keyUpdate()"
      />
      <v-btn
          color="primary"
          :href="test_url"
          target="_blank"
          elevation="1"
      >
        TEST
      </v-btn>
    </v-form>
    <v-alert
        class="mt-6"
        outlined
        type="success"
        text
        border="top"
    >
    {{ url_example }}
    </v-alert>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    key_load: true,
    key: '',
    test_url: '',
    url_example:''
  }),
  created() {
    this.initialize()
  },
  watch:{
    key(val){
      this.$bus.emit('test.key_updated', val);
      this.test_url = this.$store.state.domen + this.$url.test_user_verify + '?key=' + val
      this.url_example = this.$store.state.domen + this.$url.test_user_test1 + '?key=' + val
    }
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.test_user_info)
          .then(response => {
            this.key = response.data.key
            this.key_load = false
          })
    },
    keyUpdate() {
      this.key_load = true
      this.$axios.post(this.$url.test_user_info)
          .then(response => {
            this.key = response.data.key
            this.key_load = false
          })
    },
  }
}
</script>


<style lang="scss">
.z-spin {
  animation: spin-animation 1.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
</style>